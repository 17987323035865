import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/database'

let config = require('./../../config/config')

let firebaseConfig = {
  apiKey: config.firebase.apiKey,
  authDomain: config.firebase.authDomain,
  projectId: config.firebase.projectId,
  storageBucket: config.firebase.storageBucket,
  messagingSenderId: config.firebase.messagingSenderId,
  appId: config.firebase.appId,
  measurementId: config.firebase.measurementId
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);



const db = firebase.firestore();
const auth=firebase.auth();


const registeredUsersCollection = db.collection('users');
const kioskCollection = db.collection('kiosks');
const dialerCollection = db.collection('dialer');
const callsCollection = db.collection('calls');
const userRolesCollection = db.collection('userRoles');
const userGroupsCollection = db.collection('userGroups');
const appDataCollection = db.collection('appData');
const invitedUsersCollection = db.collection('invitedUsers');
const invitedKiosksCollection = db.collection('invitedKiosks');
const qnaCollection = db.collection('qna');
const userSessionCollection = db.collection('userSession');
const clientConfigCollection = db.collection('clientConfig');
const telepresenceCollection = db.collection('telepresence')
export {
  db,
  auth,
  firebase,
  registeredUsersCollection,
  kioskCollection,
  dialerCollection,
  callsCollection,
  userRolesCollection,
  appDataCollection,
  invitedUsersCollection,
  invitedKiosksCollection,
  qnaCollection,
  userSessionCollection,
  userGroupsCollection,
  clientConfigCollection,
  telepresenceCollection
}
