import { dispatch } from "d3";
import * as fb from "../../firebase";
import router from "../../router";
import axios from "axios";
export default {
  state: {
    enableTelepresence: false,
    callStatusDialog: {},
    meetingUrl: {},
    addToCallStatus: {},
  },
  getters: {
    getEnableTelepresence: (state) => state.enableTelepresence,
    getCallStatusDialog: (state) => state.callStatusDialog,
    getMeetingUrl: (state) => state.meetingUrl,
    addToCallStatus: (state) => state.addToCallStatus,
  },
  mutations: {
    setEnableTelepresence(state, status) {
      state.enableTelepresence = status;
    },
    setCallStatusDialog(state, callStatusObj) {
      state.callStatusDialog = callStatusObj;
    },
    setMeetingUrl(state, meetingObj) {
      state.meetingUrl = meetingObj;
    },
    setAddToCallStatus(state, obj) {
      state.addToCallStatus = obj;
    },
  },
  actions: {
    acceptCallRequest({ dispatch, getters, commit, state }, meet) {
      console.log("Meet information at accept call: ", meet);
      commit("closeIncomingCallDialog");
      commit("setMeetingCode", meet.meetingCode);
      dispatch("setUserOnCall");
      commit("setMeetingUrl", {
        meetingCode: meet.meetingCode,
        meetingId: meet.meetingId,
        initiator: meet.initiatorName,
      });
      if (router.currentRoute.path !== "/telepresence") {
        setTimeout(() => {
          router.push("/telepresence");
        }, 500);
      }
      fb.telepresenceCollection
        .doc(meet.meetingId)
        .update({
          status: "inProgress",
          acceptedBy: getters.userId,
          requestedUser: [getters.userId],
        })
        .then(() => {
          //
        });
    },

    rejectCallRequest({ getters, commit, state }, meet) {
      commit("closeIncomingCallDialog");
      getters.getRinger.pause();
      const config = {
        method: "post",
        url: "https://us-central1-ottawa-dashboard.cloudfunctions.net/apiV2/callDecline/a763cd056f1b2405788443b7197e0708",
        params: { docId: meet.meetInfo.meetingId, userId: getters.userId },
      };
      axios(config)
        .then((result) => {
          //console.log('Result from axios call', result)
        })
        .catch((error) => {
          //console.log('Error : ', error.message)
        });
    },
    endCall({ commit, getters, dispatch }) {
      console.log("Coming here at end call");
      dispatch("setUserAvailable");
      commit("setMeetingCode", null);
      console.log("Meeting ID to end Call: ", getters.getCallId);
      fb.telepresenceCollection.doc(getters.getCallId).update({
        requestedUser: fb.firebase.firestore.FieldValue.arrayRemove(
          getters.userId
        ),
        status: "Ended",
        endTime: new Date(),
      });
      router.push("/");
    },
  },
};
